import React, {ReactNode} from 'react';

const Dropdown: React.FC<{children: (toggle: () => void) => {
  toggle: () => ReactNode,
  content: () => ReactNode,
}}> = ({children}) => {
  const [open, setOpen] = React.useState(false);
  const doToggle = React.useCallback(
    () => setOpen(!open), [open, setOpen],
  );

  // Close the dropdown when we click outside of it
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!open || !ref.current) return;
      if (!ref.current.contains(event.target as Node)) {
        doToggle();
      }
    };
    document.addEventListener('click', listener);
    return () => { document.removeEventListener('click', listener); };
  }, [open, doToggle]);

  const {toggle, content} = children(doToggle);

  return <div ref={ref}>
    {toggle()}
    {open && content()}
  </div>;
};

export default Dropdown;
