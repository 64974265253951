import React from "react";

export const ensureArray = <T,>(value: NonNullable<T> | T[] | null | undefined): T[] => {
  if (value == null) return [];
  if (!(value instanceof Array)) return [value];
  return value;
};

export const inspect = <T,>(x: T, f: (x: T) => unknown): T => { f(x); return x; };

export const Text: React.FC<{children: string}> = ({children}) => <>{
  children?.split("\n").map(paragraph => <>{paragraph}</>).reduce(
    (description, paragraph) => <>{description}<br />{paragraph}</>,
  )
}</>;
