import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';

import {useOpencast} from './opencast';
import {isLoggedIn, useCurrentUser} from './user';
import sitemap from './sitemap';
import Dropdown from './Dropdown';
import useControl from './useControl';

import styles from './Login.module.css';
import appStyles from './App.module.css';
import buttonReset from './button-reset.module.css';
import button from './button.module.css';

const Login: React.FC = () => {
  const opencast = useOpencast();

  switch (opencast.loginConfig.mode) {
    case 'external':
      return <a href={opencast.loginConfig.link().href} className={styles.button}>
        <LoginButton />
      </a>;
    case 'none':
      return null;
    case 'opencast':
    default:
      return <div className={styles.dropdown}><Dropdown>{toggle => ({
        toggle: () => <button
          type="button"
          className={`${styles.button} ${styles.toggle} ${buttonReset.reset}`}
          onClick={toggle}
        >
          <LoginButton />
        </button>,
        content: () => <LoginForm toggle={toggle} />
      })}</Dropdown></div>;
  }
};

export default Login;

const LoginButton: React.FC = () => {
  const {t} = useTranslation();
  const {currentUser} = useCurrentUser();
  return <>
    <div className={appStyles.headerIcon}>
      <FontAwesomeIcon icon={faUser} size="lg" />
    </div>
    {isLoggedIn(currentUser) ? currentUser.name : t('Log In')}
  </>;
};

const LoginForm = withRouter(({toggle, history}: RouteComponentProps & {
  toggle: () => void
}) => {
  const {t} = useTranslation();

  const opencast = useOpencast();
  const {currentUser, refreshSession} = useCurrentUser();
  const loggedIn = isLoggedIn(currentUser);

  const [error, setError] = React.useState<string | undefined>();

  const [username, setUsername, controlUsername] = useControl('');
  const [password, setPassword, controlPassword] = useControl('');

  const logIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);

    const loggedIn = await opencast.logIn(username, password);
    if (loggedIn) {
      refreshSession();
      setUsername('');
    } else {
      setError('Login unsuccessful');
    }

    setPassword('');

    return loggedIn;
  };

  const logOut = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);

    await opencast.logOut();
    refreshSession();
    // Go back to the homepage so that we don't stay
    // on a page with non-public accessibility.
    history.push(sitemap.home.routeTo({}));
  };

  return loggedIn
    ? <form className={styles.logout} onSubmit={async event => {
      await logOut(event);
      toggle();
    }}>
      <button className={`${buttonReset.reset} ${styles.button}`}><Trans>
        Log Out
        </Trans></button>
    </form>
    : <form className={styles.login} onSubmit={async event => {
      const loggedIn = await logIn(event);
      if (loggedIn) toggle();
    }}>
      {error && <p><Trans>{error}</Trans></p>}
      <p>
        <input
          id="username"
          placeholder={t('username')}
          autoFocus
          {...controlUsername}
        />
      </p>
      <p>
        <input
          id="password"
          type="password"
          placeholder={t('password')}
          {...controlPassword}
        />
      </p>
      <p>
        <button className={button.class}><Trans>
          Log in
          </Trans></button>
      </p>
      <p><Trans>
        Please use your MEDonline Account for login!
        In case you forgot your password:
        students please contact one stop shop,
        staff please contact IT service line.
        </Trans></p>
    </form>;
});
