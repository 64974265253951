import i18n from 'i18next';
import {initReactI18next as reactIntegration} from 'react-i18next';
import browserDetector from 'i18next-browser-languagedetector';

i18n
  .use(reactIntegration)
  .use(browserDetector)
  .init({
    resources: {
      de: {
        translation: {
          // Page title
          'VITAL—Video Portal of the Medical University of Graz':
            'VITAL: Video-Portal der medizinischen Universität Graz',

          // Navigation
          'Home': 'Startseite',

          // Authentication
          'Log In': 'Anmelden',
          'Log in': 'Anmelden',
          'username': 'Benutzername',
          'password': 'Passwort',
          ['Please use your MEDonline Account for login! ' +
            'In case you forgot your password: ' +
            'students please contact one stop shop, ' +
            'staff please contact IT service line.']:
            'Bitte benutzen Sie ihr MEDonline-Konto, ' +
            'um sich anzumelden. ' +
            'Falls Sie Ihr Passwort vergessen haben: ' +
            'Studenten kontaktieren bitte den One-Stop-Shop, ' +
            'Angestellte wenden sich bitte an die IT-Serviceleitung.',
          'Log Out': 'Abmelden',

          // Search
          'Search': 'Suche',
          'Search results for "{{query}}"': 'Suchergebnisse für „{{query}}“',

          // Home
          'New Recordings': 'Neue $t(Recordings)',
          'Watch now': 'Jetzt ansehen',

          // Courses
          'Courses ({{count}})': '$t(Courses) ({{count}})',
          'Location': 'Ort',

          // Recordings
          'Recordings ({{count}})': '$t(Recordings) ({{count}})',
          'Course': 'Kurs',
          'Date': 'Datum',
          'Duration': 'Länge',

          // Misc
          'Recordings': 'Aufzeichnungen',
          'Courses': 'Kurse',
          'Title': 'Titel',
          'Presenters': 'Vortragende',
          'multiple presenters': 'mehrere Vortragende',

          // 404
          '<0>Not found</0><p>The site you requested could not be found.</p>':
            '<0>Nicht gefunden</0><p>Die Seite, die Sie angefordert haben, konnte nicht gefunden werden.</p>',
          '<0>Page not visible in anonymous mode</0><1>You possibly try to access a page which is only visible when you are logged in.<br/>Please log in with your MEDonline account in the upper right corner!</1>':
            '<0>Seite in anonymer Ansicht nicht sichtbar</0><1>Sie versuchen möglicherweise eine Aufzeichnung aufzurufen die nur in eingeloggtem Zustand sichtbar ist.<br/>Bitte loggen Sie sich mit Ihrem MEDonline Account rechts oben ein!</1>',

          // Selecting the right logo (see below)
          'logos/en.svg': 'logos/de.svg',
          // alt for the logo
          'Medical University of Graz': 'Medizinische Universität Graz',

          // Pagination
          'First page': 'Erste Seite',
          'Previous page': 'Vorherige Seite',
          'Page {{page}}': 'Seite {{page}}',
          'Next page': 'Nächste Seite',
          'Last page': 'Letzte Seite',
          "Items per page": "Einträge pro Seite",
          "Reload": "Neu laden",

          // Livestreams
          'Livestreams ({{count}})': '$t(Livestreams) ({{count}})',
          'Livestreams are not available.': 'Livestreams sind nicht verfügbar.',
          'No transmission is currently active for this room.': 'Für diesen Raum ist derzeit keine Übertragung aktiv.',
          'Livestreams are currently only supported in the following browsers: Firefox, Chrome und MS Edge.':
            'Livestreams werden momentan ausschließlich in folgenden Browsern unterstützt: Firefox, Chrome und MS Edge.',

          // Livestream
          'This channel is currently OFFLINE. The video stream will load automatically once the channel goes live.' :
            'Dieser Kanal ist derzeit OFFLINE. Der Videostream wird automatisch geladen, sobald der Kanal live geht.',
          'The stream is finished. Now running: {{livestreamTitle}}. To switch, reload the page.' :
            'Der Stream ist beendet. Es läuft nun {{livestreamTitle}}. Um zu Wechseln, laden sie die Seite neu.',
          'The stream is finished.' :
            'Der Stream ist beendet.',
          'Reload page now' :
            'Jetzt Seite neuladen',
        },
      },
    },

    // We use content as translation keys
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      // React takes care of this for us
      escapeValue: false,
    },
  });

export default i18n;
