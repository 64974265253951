import React, {ReactElement} from 'react';
import Wait from './Wait';
import {useAsync} from 'react-async';

import {User, AuthenticatedUser, ANONYMOUS, useCurrentUser, UserForList} from './user';

import {ensureArray} from './util';

export type Duration = number;  // in milliseconds

export const formatDuration = (duration: Duration): string => {
  duration = Math.floor(duration / 1000);
  const seconds = String(duration % 60);
  duration = Math.floor(duration / 60);
  const minutes = String(duration % 60);
  const hours = Math.floor(duration / 60);
  return `${
    hours > 0
      ? `${hours}:`
      : ''
  }${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
};

export interface Recording {
  readonly id: string;
  readonly title: string;
  readonly course?: Course;
  readonly date: Date;
  readonly duration: Duration;
  readonly presenters: string[];
  readonly link: URL;
  readonly preview?: string;
}

export interface Course {
  readonly id: string;
  readonly title: string;
  readonly description?: string;
  readonly location?: string;
}

export interface PaginationOptions {
  readonly limit: number,
  readonly page: number,
}

export enum SortDirection {
  ASC = '',
  DESC = '_DESC',
}

export interface SortOptions {
  readonly field: 'DATE_CREATED' |
    'DATE_PUBLISHED' |
    'TITLE' |
    'SERIES_ID' |
    'MEDIA_PACKAGE_ID' |
    'CREATOR' |
    'CONTRIBUTOR' |
    'LANGUAGE' |
    'LICENSE' |
    'SUBJECT' |
    'DESCRIPTION' |
    'PUBLISHER' |
    'START';
  readonly direction: SortDirection;
}

// Contains information about a livestream event
export interface Livestream {
  readonly id: string,
  readonly viewer?: URL,
  readonly title?: string,
  readonly description?: string,
  readonly unrestricted?: boolean,
  readonly previews?: { [key: string]: string[] }
  paellalink?: URL,
}

// A room hosting a livestream event
export interface Channel {
  readonly id: string,
  readonly name: string,
}

export type SearchOptions = {sort?: SortOptions} & Partial<PaginationOptions>;

export type Page<T> = T & {total: number};

type LoginConfig = {
  mode: 'none' | 'opencast';
} | {
  mode: 'external';
  link: () => URL;
};

export class Opencast {
  readonly apiRoot: URL;
  readonly playerLink: (id: string) => URL;
  readonly vitalPlayerLink: (id: string) => URL;
  private readonly chatSockets: Map<string, WebSocket>;
  readonly loginConfig: LoginConfig;

  constructor({
    apiRoot = new URL(window.location.origin),
    playerLink = id => new URL(`play/${id}`, apiRoot),
    vitalPlayerLink = id => new URL(`/vital-paella/ui/watch.html?id=${id}`, apiRoot),
    loginConfig = {mode: 'opencast'}
  }: {
    apiRoot: URL,
    playerLink: (id: string) => URL,
    vitalPlayerLink: (id: string) => URL,
    loginConfig: LoginConfig
  }) {
    this.apiRoot = apiRoot;
    this.playerLink = playerLink;
    this.vitalPlayerLink = vitalPlayerLink;
    this.loginConfig = loginConfig;

    this.chatSockets = new Map();
  }

  private request = async (
    endpoint: string,
    options: RequestInit = {}
  ): Promise<Response> => {
    const response = await fetch(
      `${this.apiRoot.href}${endpoint}`,
      {
        ...options,
        credentials: 'include',
      },
    );
    if (!response.ok) throw response;
    return response;
  }

  logIn = async (username: string, password: string) => {
    const userData = new URLSearchParams();
    userData.append('j_username', username);
    userData.append('j_password', password);
    userData.append('_spring_security_remember_me', 'on');
    const response = await this.request('j_spring_security_check', {
      method: 'POST',
      body: userData,
    });
    return !(new URL(response.url).searchParams.has('error'));
  }

  logOut = async (): Promise<void> => {
    await this.request('j_spring_security_logout');
  }

  currentUser = async (): Promise<User> => {
    const result = await this.request('info/me.json');
    const userInfo = await result.json();
    if (userInfo.roles.includes('ROLE_USER')) {
      return new AuthenticatedUser(
        userInfo.user.username,
        userInfo.user.name,
        userInfo.roles);
    } else {
      return ANONYMOUS;
    }
  }

  parseUsers = ({
    username,
    fullName,
    viewerId,
    lastHeardFrom,
    roles
  }: Record<string, any>): UserForList => ({
    username,
    fullName,
    viewerId,
    lastHeardFrom,
    roles
  })

  allUsers = async (channelID: string): Promise<{users: UserForList[]}> => {
    const response = await this.request("vital-livestream/viewer/" + channelID + "?cutoff=91");
    const result = await response.json();
    return { users: ensureArray(result).map(this.parseUsers) }
  }

  private search = async (
    entity: 'episode' | 'series',
    {
      id,
      query,
      series,
      limit,
      page,
      sort = {field: 'TITLE', direction: SortDirection.ASC},
    }: {
      query?: string,
      id?: string,
      series?: string,
    } & SearchOptions = {},
  ): Promise<Page<{results: any}>> => {
    const searchParams = String(new URLSearchParams({
      ...(query && {q: query.trim()}),
      ...(id && {id}),
      ...(series && {sid: series}),
      ...(limit && {limit: String(limit)}),
      ...(page && limit && {offset: page * limit}),
      sort: `${sort.field}${sort.direction}`,
    } as Record<string, string>));
    const response = await this.request(
      `search/${entity}.json${searchParams && `?${searchParams}`}`,
    );
    const {'search-results': {
      result: results,
      total,
    }} = await response.json();
    return {results, total};
  }

  private createChatSocket = (id: string): WebSocket => {
    const url = new URL(`/vitalchat-websocket/${id}`, this.apiRoot);
    if (window.location.protocol === "https:") {
      url.protocol = "wss:";
    } else {
      url.protocol = "ws:";
    }

    const socket = new WebSocket(url.href);
    return socket;
  }

  getChatSocket = (id: string): WebSocket => {
    const existingChatSocket = this.chatSockets.get(id);
    if (existingChatSocket) {
      return existingChatSocket;
    } else {
      const newChatSocket = this.createChatSocket(id);
      this.chatSockets.set(id, newChatSocket);
      return newChatSocket;
    }
  }

  closeChatSocket = (id: string): void => {
    const chatSocket = this.chatSockets.get(id);
    if (chatSocket) {
      chatSocket.close();
      this.chatSockets.delete(id);
    }
  }

  parseLivestream = ({
    id,
    viewer,
    title,
    description,
    unrestricted,
    previews,
  }: Record<string, any>): Livestream => ({
    id,
    viewer: new URL(viewer),
    title,
    description,
    unrestricted,
    previews,
    paellalink: this.vitalPlayerLink(id),
  })

  private vitallivestream = async ({
    channelId,
    ipaddress,
  }: {
    channelId?: string,
    ipaddress?: string,
  }):
  Promise<{results: any}> => {
    const livestreamsResponse = await this.request(
      `vital-livestream/livestream${channelId ? `/${channelId}` : ``}`
      + `${ipaddress ? `?ipaddress=${ipaddress}` : ``}`,
    );
    const results = await livestreamsResponse.json();
    return results;
  }

  livestreams = async():
    Promise<{livestreams: Livestream[]}> => {
      const results = await this.vitallivestream({channelId: undefined});
      return {
        livestreams: ensureArray(results).map(this.parseLivestream),
      }
    }

  livestream = async (id: string, userIpAddress?: string): Promise<Livestream | undefined> => {
    const livestream = await this.vitallivestream({channelId: id, ipaddress: userIpAddress});
    return livestream && this.parseLivestream(livestream);
  };

  parseChannel = ({
    id,
    name,
  }: Record<string, any>): Channel => ({
    id,
    name,
  })

  private availableChannels = async ():
    Promise<{results: any}> => {
      const response = await this.request(
        `vital-livestream/availablechannels`,
      );
      const results = await response.json();
      return results;
    }

  channels = async():
    Promise<{channels: Channel[]}> => {
      const results = await this.availableChannels();
      return {
        channels: ensureArray(results).map(this.parseChannel),
      }
    }

  private parseEpisode = ({id, mediapackage: {
    title,
    series,
    seriestitle,
    start,
    duration,
    creators,
    attachments,
  }}: Record<string, any>): Recording => ({
    id,
    title,
    course: series && {id: series, title: seriestitle},
    date: new Date(start),
    duration: parseInt(duration),
    presenters: creators ? ensureArray(creators.creator) : [],
    link: this.playerLink(id),
    preview: attachments && (() => {
      const preview = ensureArray(attachments.attachment)
        .find(({type}) => (type as string).endsWith('/player+preview'));
      return preview && preview.url;
    })(),
  })

  episodes = async (
    args: {
      query?: string,
      series?: string,
    } & SearchOptions = {},
  ): Promise<Page<{recordings: Recording[]}>> => {
    const {results, total} = await this.search('episode', args);
    return {
      recordings: ensureArray(results).map(this.parseEpisode),
      total,
    }
  }

  episode = async (id: string): Promise<Recording | undefined> => {
    const episode = await this.search('episode', {id});
    return episode.results && this.parseEpisode(episode.results);
  };

  private parseSeries = ({
    id,
    dcTitle: title,
    dcDescription: description
  }: Record<string, any>): Course => ({id, title, description});

  series = async (
    args: {query?: string} & SearchOptions
  ): Promise<Page<{courses: Course[]}>> => {
    const {results, total} = await this.search('series', args);
    return {
      courses: ensureArray(results).map(this.parseSeries),
      total,
    };
  }

  singleSeries = async (id: string): Promise<Course | undefined> => {
    const series = await this.search('series', {id});
    return series.results && this.parseSeries(series.results);
  };
}

const Context = React.createContext<Opencast | undefined>(undefined);

export const useOpencast = () => React.useContext(Context)!;

const configureOpencast: Promise<Opencast> =
  fetch('/config.json')
    .then(response => {
      if (response.ok) return response.json();
      if (response.status === 404) return {} as any;
      throw new Error('invalid configuration');
    })
    .then(({apiRoot, playerLink, vitalPlayerLink, login}) => new Opencast({
      ...(apiRoot && {apiRoot: new URL(apiRoot)}),
      ...(playerLink && {playerLink: id => new URL(makeTemplate(
        'id',
        playerLink
      )(id), apiRoot)}),
      ...(vitalPlayerLink && {vitalPlayerLink: id => new URL(makeTemplate(
        'id',
        vitalPlayerLink
      )(id), apiRoot)}),
      ...(login && {loginConfig: {...login, ...(
        login.link && {
          link: () => new URL(makeTemplate(
            'referrer',
            login.link,
          )(encodeURIComponent(window.location.href)))
        }
      )}})
    }));

export const Component: React.FC = ({children}) => {
  return <Wait state={useAsync({
    promise: configureOpencast as Promise<Opencast | undefined>,
  })}>{
    opencast => <Context.Provider value={opencast}>
      {children}
    </Context.Provider>
  }</Wait>
};

export const Search: <T>(props: {
  fetch: (opencast: Opencast) => Promise<T | undefined>,
  children: (result: T) => ReactElement | null,
}) => ReactElement | null = ({fetch, children}) => {
  const state = useAsync(
    React.useCallback(
      ({opencast}: {opencast: Opencast}) => fetch(opencast),
      [fetch]
    ),
    {
      opencast: useOpencast(),
      watch: useCurrentUser().currentUser,
    },
  );
  return <Wait state={state}>{children}</Wait>
};

export const makeTemplate = (
  name: string,
  template: string,
): (value: string) => string => value => {
  // eslint-disable-next-line no-new-func
  const subst = new Function(name, `return \`${template}\`;`);
  return subst(value);
};
