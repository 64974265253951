import React, { ErrorInfo } from 'react';
import {Trans} from 'react-i18next';
import DOMPurify from 'dompurify';

import {Channel, Livestream, Search} from './opencast';

import sitemap from './sitemap';

import styles from './recordings.module.css';

/**
 * Intended to catch errors in the Livestreams component, particularly http request errors.
 * Prevents the portal from crashing if Opencast is missing the livestreaming modules.
 */
class LivestreamsErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, info)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (<>
        <h1>Livestreams</h1>
        <div><Trans>Livestreams are not available.</Trans></div>
      </>)
    }
    return this.props.children
  }
}

/**
 * Helper function to get a random URL from all preview images
 */
const selectRandomThumbnail = (previews: Livestream["previews"]) => {
  if(previews) {
    var previewLinks = Object.keys(previews).map(function (key: string) {
      return previews[key]
    })
    .flat();
    return previewLinks[Math.floor(Math.random() * previewLinks.length)];
  }
}

const Thumbnail = (item: Livestream) => {
  if (item.viewer) {
    return (
      <sitemap.livestream.Link id={item.id} className={styles.thumbnail}>
        <img src={selectRandomThumbnail(item.previews)} alt="thumbnail"/>
        {}
      </sitemap.livestream.Link>
    )
  } else {
    return (
      <sitemap.livestream.Link id={item.id} className={styles.thumbnail}>
        <div className={styles.thumbnail}>Offline</div>
      </sitemap.livestream.Link>
    )
  }
}

/**
 * Returns a component that renders one recording.
 */
const Item: React.FC<{livestream: Livestream, channel: Channel}> = (({livestream, channel}) => {
    return (
      <div className={styles.item}>
          {Thumbnail(livestream)}
        <div className={styles.itemInfo}>
          <h3>
            <sitemap.livestream.Link id={livestream.id}>
              {channel.name}
            </sitemap.livestream.Link>
          </h3>
          <div className={styles.datePresenter}>
            <span>{livestream.title ?
              livestream.title :
              <Trans>No transmission is currently active for this room.</Trans>
            }</span>
          </div>
          {livestream.description &&
            <div className={styles.datePresenter}
              dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(livestream.description)}}
            >
            </div>
          }
        </div>
      </div>
    );
  }
);

const groupChannelAndLivestreams = (channels: Channel[], livestreams: Livestream[]) => {
  var groups = []
  for (const channel of channels) {
    let livestream: Livestream = {id: channel.id, paellalink: undefined}

    const livestreamExist = livestreams.find(obj => { return obj.id === channel.id})
    if (livestreamExist) {
      livestream = livestreamExist
    }
    groups.push({channel, livestream})
  }
  return groups
}

const LivestreamList: React.FC<{
  channels: Channel[],
}> = ({channels}) => (
  <Search fetch={
    React.useCallback(opencast => opencast.livestreams(), [])
  }>{
    ({livestreams}) => <>
      {groupChannelAndLivestreams(channels, livestreams).map(item =>
        <Item key={item.channel.id} livestream={item.livestream} channel={item.channel} />
      )}
    </>
  }</Search>
);

const Livestreams: React.FC = () => {
  return (
  <LivestreamsErrorBoundary>
    <Search fetch={
      React.useCallback(opencast => opencast.channels(), [])
    }>{
      ({channels}) => <>
        <h1><Trans count={channels.length}>
          Livestreams ({{count: channels.length}})
        </Trans></h1>
        {/* TODO: Support Safari */}
        <div><Trans>Livestreams are currently only supported in the following browsers: Firefox, Chrome und MS Edge.</Trans></div>
        <LivestreamList
          channels={channels}
        />
      </>
    }</Search>
  </LivestreamsErrorBoundary>
  )
};

export default Livestreams;
