import React, {useEffect, useRef, useState} from 'react';
import {Channel, Livestream as Model, useOpencast} from './opencast';

import styles from './Recording.module.css';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrentUser } from './user';
import LivestreamChat from './LivestreamChat';
import { ipaddress } from './Ipaddress';

interface stateInterface {
  data: Model | undefined,
  error: boolean,
  loading: boolean
}

const Player: React.FC<{livestream: Model, channel: Channel | undefined}> = ({
  livestream,
  channel,
}) => {
  const FETCH_INTERVAL_MS = 5000
  const [livestreamState, setState] = useState<stateInterface>({ data: livestream, error: false, loading: false })
  const [userIpAddress, setUserIpAddress] = useState<string | undefined>(undefined)
  const myOpencast = useOpencast()
  const {t} = useTranslation()

  // Function that refreshes the page.
  // Needs to be a seperate function for button onClick
  function refreshPage() {
    window.location.reload()
  }

  // Repeatedly fetches for a livestream
  const fetchData = React.useCallback(
    async() => {
      let userip = undefined
      if (!userIpAddress) {
        userip = await ipaddress()
        setUserIpAddress(userip)
      }

      setState(state => ({ data: state.data, error: false, loading: true }))

      myOpencast.livestream(livestream.id, userip)
        .then(newData => {
          setState({ data: newData, error: false, loading: false })
        })
        .catch(function(error) {
          console.log(error)
          setState({ data: undefined, error: true, loading: false })
        })

    },
    [livestream.id, myOpencast, userIpAddress]
  )

  useEffect(() => {
    const interval = setInterval(fetchData, FETCH_INTERVAL_MS)
    return () => clearInterval(interval) // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [fetchData])

  // If user is not permitted to view the stream, return error page
  const {currentUser} = useCurrentUser()
  if (!livestream.unrestricted && !currentUser.isLoggedIn) {
    return <Trans>
    <h1>Page not visible in anonymous mode</h1>
    <p>
      You possibly try to access a page which is only visible when you are logged in.<br />
      Please log in with your MEDonline account in the upper right corner!
    </p>
  </Trans>
  }

  const renderPlayer = () => {
    if (!livestreamState.data) {
      return (<>
        <div className={styles.heightDummy} />
        <div className={styles.playerReplacementImage}>
          {t('The stream is finished.')}
        </div>
      </>)
    } else if (livestreamState.data && livestreamState.data.title !== livestream.title) {
      return (<>
        <div className={styles.heightDummy} />
        <div className={styles.playerReplacementImage}>
          <div>{t('The stream is finished. Now running: {{livestreamTitle}}. To switch, reload the page.', {livestreamTitle: livestreamState.data.title})}</div>
          <button onClick={refreshPage}>{t('Reload page now')}</button>
        </div>
      </>)
    } else {
      return (<>
        <div className={styles.heightDummy} />
        <iframe
          className={styles.player}
          title={livestream.title}
          src={livestream.paellalink?.href + `&ipaddress=` + userIpAddress }
          allowFullScreen={true} />
      </>)
    }
  }

  return <div className={styles.playerWrap}>
    <div className={styles.playerWrap}>
      {renderPlayer()}
    </div>

    <h1>{channel ? channel.name : livestream.id}</h1>
    {livestream.title && <h3>{livestream.title}</h3>}
  </div>;
};

/**
 * Displayed when the initial fetch request failed
 */
const NoLivestream: React.FC<{name: string}> = ({name}) => {
  return <>
    <div className={styles.playerWrap}>
      <div className={styles.heightDummy} />
      <div className={styles.playerReplacementImage}><Trans>
        This channel is currently OFFLINE. The video stream will load automatically once the channel goes live.
      </Trans></div>
    </div>
    <h1>{name}</h1>
  </>;
}

/**
 * Repeatedly fetches for a livestream until it gets one.
 * Displays placeholder when it gets a livestream.
 */
const Livestream: React.FC<{id: string}> = ({id}) => {

  const FETCH_INTERVAL_MS = 10000
  const myOpencast = useOpencast()
  const [livestream, setState] = useState<Model>()
  const intervalRef = useRef<NodeJS.Timeout>()
  const [channel, setChannel] = useState<Channel>()

  const fetchData = React.useCallback(
    async() => {
      myOpencast.livestream(id)
        .then(newData => {
          setState(newData)
          if (newData && newData.id && intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        })
        .catch(function(error) {
          console.log(error)
          setState(undefined)
        })
    },
    [id, myOpencast]
  )

  const fetchChannel = React.useCallback(
    async() => {
      myOpencast.channels()
        .then(newData => {
          if (newData && newData.channels) {
            setChannel(newData.channels.find(channel => channel.id === id))
          }
        })
        .catch(function(error) {
          console.log(error)
          setChannel(undefined)
        })
    },
    [id, myOpencast]
  )

  // Fetch once
  useEffect(() => {
    fetchData()
    intervalRef.current = setInterval(fetchData, FETCH_INTERVAL_MS);
    return () => { if (intervalRef.current) clearInterval(intervalRef.current); }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchChannel()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // If livestream is running, render the livestream player component
  if (livestream) {
    return (
      <div className={styles.livestream}>
        <Player livestream={livestream} channel={channel}></Player>
        <LivestreamChat id={livestream.id} />
      </div>
    )
  // Else render a placeholder until one starts running
  } else {
    return (
      <NoLivestream name={channel ? channel.name : id} />
    )
  }
};

export default Livestream;
