import { faDownload, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {Trans} from 'react-i18next';
import {MoonLoader as LoadingIndicator} from 'react-spinners';
import { CSSTransition } from 'react-transition-group';

import styles from './livestreamchat.module.css';
import button from './button.module.css';

import { useCurrentUser, UserForList } from './user';
import { useOpencast } from './opencast';


interface chatMessage {
  user: string | undefined,
  time: Date | undefined,
  message: string,
}

const ChatHistory: React.FC<{
    socket: WebSocket,
    isDownload: boolean,
    setDownloadFunc: Dispatch<SetStateAction<boolean>>
  }> = ({
    socket,
    isDownload,
    setDownloadFunc
  }) => {

  const [messages, setMessages] = useState<chatMessage[]>([{user: undefined, time: undefined, message: ""}]);
  const messagesEndRef = useRef<HTMLDivElement>(null)

  socket.onmessage = (message) => {
    const parsedMessage: chatMessage = parseMessage(message.data)
    parsedMessage.time = parsedMessage.time ? new Date(parsedMessage.time) : undefined
    setMessages(oldArray => [...oldArray, parsedMessage])
    console.log("On Message: " + parsedMessage?.message)
  }

  const parseMessage = (message: string) => {
    return JSON.parse(message)
  }

  const scrollToBottom = () => {
    if (messagesEndRef !== null && messagesEndRef.current !== null) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const makeTextFile = () => {
    if (isDownload) {
      const element = document.createElement("a");
      const file = new Blob(
        [messages
          .map(function(elem){
            if(elem.user === undefined && elem.time === undefined) {
              return "Welcome to the chat!\n"
            }
            return elem.user + " " + elem.time?.toLocaleTimeString() + "\n" + elem.message + "\n"
          })
          .join("\n")],
        {type: "/text/plain"}
      )
      element.href = URL.createObjectURL(file);
      element.download = "chatLog.txt";
      document.body.appendChild(element); // Required for this to work in Firefox
      element.click();

      setDownloadFunc(false)
    }
  }

  // Autoscroll callback
  useEffect(scrollToBottom, [messages]);

  // Download callback
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(makeTextFile, [isDownload])

  const renderMessage = (message: chatMessage, key: number) => {
    return (
      <div className={styles.chatMessage} key={key}>
        <div className={styles.chatMessageTopRow}>
          <div className={styles.chatMessageUserName}>{message.user}</div>
          <div className={styles.chatMessageTime}>{message.time?.toLocaleTimeString()}</div>
        </div>
          <div>{message.message}</div>
      </div>
    )
  }

  return(<>
    {/* <h3 className={appStyles.headerIcon}>Chat</h3> */}
    <div className={styles.chatHistory}>
      {messages.map((message, i) => {
        return renderMessage(message, i)
      })}
      <div ref={messagesEndRef} />
    </div>
  </>)
}

const SendMessage: React.FC<{
    socket: WebSocket,
    setDownloadFunc: Dispatch<SetStateAction<boolean>>,
    isClosed: boolean
  }> = ({
    socket,
    setDownloadFunc,
    isClosed
  }) => {

  const [myMessage, setMyMessage] = useState('');
  const {currentUser} = useCurrentUser()
  // TODO: Make configurable
  const canDownloadRoles = ["ROLE_ADMIN", "ROLE_GROUP_PERS"]

  const updateMyMessage = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setMyMessage(e.target.value)
  }

  const sendMessage = () => {
    if(!myMessage) return
    let payload = {
      user: currentUser.displayName,
      time: new Date(),
      message: myMessage,
    }
    socket.send(JSON.stringify(payload))
    console.log("Sent: " + payload)
    setMyMessage('')
  }

  const render = () => {
    if (!isClosed) {
      return (
        <div className={styles.sendMessage}>

        <textarea
          className={styles.sendMessageField}
          value={myMessage}
          placeholder="Send message to public chat"
          maxLength={200}
          onChange={updateMyMessage}
          onKeyPress={(e) => {
            if (e.repeat) return
            if (e.key === "Enter") {
              e.preventDefault()
              sendMessage()
            }
          }}
        />
        <button
          className={`${styles.sendMessageButton} ${styles.button} ${button.class}`}
          onClick={sendMessage}
          >
            <FontAwesomeIcon icon={faPaperPlane} className={styles.icon} size="lg" />
        </button>
        {canDownloadRoles.some(r=> currentUser.roles.includes(r)) &&
        <button
          className={`${styles.downloadChatLogButton} ${styles.button} ${button.class}`}
          onClick={() => setDownloadFunc(true)}
          >
            <FontAwesomeIcon icon={faDownload} className={styles.icon} size="lg" />
        </button>
        }
      </div>
      )
    } else {
      return(
        <div className={styles.sendMessage}>Lost connection to chat</div>
      )
    }
  }

  return (
    render()
  )
}

const LiveStreamChat: React.FC<{id: string}> = ({id}) => {

  const [isMounted, setIsMounted] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isClosed, setIsClosed] = useState(false)
  const [isDownload, setIsDownload] = useState(false)

  const opencast = useOpencast()

  // Close websocket on unmount
  useEffect(() => {
    return () => {
      opencast.closeChatSocket(id)
      setIsMounted(false)
      console.log("Attempted close")
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ask user to log in to chat
  // This must be positioned before any socket methods to avoid accidentally
  // opening a socket for a user that is not logged in
  const {currentUser} = useCurrentUser()
  if (!currentUser.isLoggedIn) {
    return <Trans>
    <p>
      The chat is only visible when you are logged in.<br />
      Please log in with your MEDonline account in the upper right corner!
    </p>
  </Trans>;
  }

  const socket = opencast.getChatSocket(id)

  socket.onopen = () => {
    console.log("On Open")
    setIsLoading(false)
  }

  socket.onerror = (event: Event) => {
    console.log("OnError: " + event)
    setIsClosed(true)
  }

  socket.onclose = (event: { code: number; reason: string; }) => {
    console.log("On Close. Code: " + event.code +  ". Reason: " + event.reason)
    if (isMounted) {
      setIsClosed(true)
    }
  }

  // Render auto-connecting to chat
  if (isLoading) return <CSSTransition
    in={true}
    timeout={0}
    appear={true}
    classNames={styles}
  ><div className={styles.loading}>
    <LoadingIndicator css="animation-duration: 2s;" color={styles.loadingColor} />
  </div></CSSTransition>;

  // Render Chat
  return (
    <div className={styles.chat}>
      <UserList id={id}/>
      <ChatHistory socket={socket}
        isDownload={isDownload}
        setDownloadFunc={setIsDownload}
      />
      <SendMessage
        socket={socket}
        setDownloadFunc={setIsDownload}
        isClosed={isClosed}
      />
    </div>
  );
};


const UserList: React.FC<{
    id: string,
  }> = ({
    id,
  }) => {

  const {currentUser} = useCurrentUser()
  const [users, setUser] = useState<UserForList[]>([])
  const canOpenList = ["ROLE_GROUP_ADMIN", "ROLE_ADMIN"]
  const opencast = useOpencast();
  let guestID = 1;
  const anonym = ["ROLE_ANONYMOUS"];

  const fetchUser = React.useCallback(
    async() => {
      opencast.allUsers(id)
        .then(newData => {
          if (newData && newData.users) {
            setUser(newData.users)
          }
        })
        .catch(error => {
          console.log(error)
          setUser([])
        })
    },
    [id, opencast]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      fetchUser();
    }, 10000);
    fetchUser();
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [])

  // if user is not logged in, show as "Gast / anonym id"
  users.forEach(user => {
    if(anonym.some(role => JSON.stringify(user.roles.includes(role))) && user.roles.length === 1) {
      user.fullName = "Gast";
      user.username = "anonym " + guestID;
      guestID += 1;
    }
  });

  users.sort((a, b) => (a.username > b.username) ? 1 : -1)

  const showViewingId = () => {
    const id = users.find(e => e.username === currentUser.name);
    return id?.viewerId;
  }

  const downloadUserList = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [users
        .map(function(user){
          return user.fullName + " (" + user.username + ", " + user.viewerId + ")"
        })
        .join("\n")],
      {type: "/text/plain"}
    )
    element.href = URL.createObjectURL(file);
    element.download = "userList.txt";
    document.body.appendChild(element); // Required for this to work in Firefox
    element.click();
  }

  const renderViewerList = (isAdmin: boolean) => {
    if(isAdmin === true) {
      return(
        <div className={styles.userList}>
          <table className={styles.viewerInfo}>
            <tr style={{fontWeight: 'bold'}}>Welcome to the chat!</tr>
            <tr>{users.length} Person(en) online</tr>
            <tr>Viewing-ID: {showViewingId()}</tr>
            {canOpenList.some(r=> currentUser.roles.includes(r)) &&
            <details>
              <summary className={`${styles.viewerListButton} ${styles.button} ${button.class}`}>Teilnehmer*innen</summary>
              <button
                className={`${styles.downloadUserList} ${styles.button} ${button.class}`}
                onClick={() => downloadUserList()}>
                <FontAwesomeIcon icon={faDownload} className={styles.icon} size="lg" />
              </button>
              <table className={styles.viewerList}>
                {users.map((users) => <tr key={users.viewerId}>{users.fullName} / {users.username}</tr>)}
              </table>
            </details>
            }
          </table>
        </div>
      )
    }
    else {
      return(
        <div className={styles.userList}>
          <table className={styles.viewerInfo}>
            <tr style={{fontWeight: 'bold'}}>Welcome to the chat!</tr>
            <tr>{users.length} Person(en) online</tr>
            <tr>Viewing-ID: {showViewingId()}</tr>
          </table>
        </div>
      )
    }
  }

  return(
      <div>
        {renderViewerList(canOpenList.some(r=> currentUser.roles.includes(r)))}
      </div>
  )
};

export default LiveStreamChat;
