import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';

import {AsyncState} from 'react-async';

import {MoonLoader as LoadingIndicator} from 'react-spinners';
import {CSSTransition} from 'react-transition-group';

import styles from './Wait.module.css';

import {useCurrentUser} from './user';

export default function Wait<T>({state, children}: {
  state: AsyncState<T | undefined>,
  children: (data: T) => ReactElement | null,
}): ReactElement | null {
  const {currentUser} = useCurrentUser();
  const {data, isLoading, error} = state;

  if (error != null) throw error;
  if (isLoading) return <CSSTransition
    in={true}
    timeout={0}
    appear={true}
    classNames={styles}
  ><div className={styles.loading}>
    <LoadingIndicator css="animation-duration: 2s;" color={styles.loadingColor} />
  </div></CSSTransition>;
  if (data != null) {
    return children(data);
  } else {
    if (currentUser.isLoggedIn) {
      return <Trans>
        <h1>Not found</h1>
        <p>The site you requested could not be found.</p>
      </Trans>;
    } else {
      return <Trans>
        <h1>Page not visible in anonymous mode</h1>
        <p>
          You possibly try to access a page which is only visible when you are logged in.<br />
          Please log in with your MEDonline account in the upper right corner!
        </p>
      </Trans>;
    }
  }
}
