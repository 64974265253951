import React, {useCallback} from 'react';
import {Trans} from 'react-i18next';
import {Recording as Model, Search} from './opencast';
import sitemap from './sitemap';

import styles from './Recording.module.css';

const Player: React.FC<Model> = ({
  title,
  course,
  presenters,
  link,
}) => {
  const presentersOut = (() => {
    switch (presenters.length) {
      case 0: return null;
      case 1: return ' ' + presenters[0];
      default: return <ul>{presenters.map(p => <li>{p}</li>)}</ul>;
    }
  })();

  return <>
    <div className={styles.playerWrap}>
      <div className={styles.heightDummy} />
      <iframe
        className={styles.player}
        title={title}
        src={link.href}
        allowFullScreen={true} />
    </div>
    <h1>{title}</h1>
    {course && <h2>
      <sitemap.course.Link id={course.id}>
        {course.title}
      </sitemap.course.Link>
    </h2>}

    {presentersOut && <>
      <em><Trans>Presenters</Trans>:</em>
      {presentersOut}
    </>}
  </>;
};

const Recording: React.FC<{id: string}> = ({id}) =>
  <Search fetch={
    useCallback(opencast => opencast.episode(id), [id])
  }>{Player}</Search>;

export default Recording;
