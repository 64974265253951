import React from 'react';
import {Trans} from 'react-i18next';

import {Text} from './util';

import {Search} from './opencast';

import {RecordingList} from './recordings';
import {usePagination} from './pagination';

import styles from './Course.module.css';


const Course: React.FC<{id: string}> = ({id}) => {
  const pagination = usePagination();
  return <Search fetch={
    React.useCallback(opencast => {
      const episodes = opencast.episodes({series: id, ...pagination});
      return opencast.singleSeries(id).then(
        series => series && Promise.all([series, episodes]),
      );
    }, [id, pagination])
  }>{([{title, description}, {recordings, total: count}]) => <>
    <h1>
      {title}
      <span className={styles.subtitle}>
        <Trans {...{count}}>
          {{count}} Recording(s)
        </Trans>
      </span>
    </h1>

    {description && <p><Text>{description}</Text></p>}

    <RecordingList
      showCourse={false}
      items={recordings}
      pagination={{total: count, ...pagination}}
    />
  </>}</Search>;
};

export default Course;
