import React from 'react';

const useControl = <T>(initialValue: T) => {
  const [value, setValue] = React.useState(initialValue);
  const onChange = React.useCallback(
    ({target: {value}}) => setValue(value),
    [setValue],
  );

  return [value, setValue, {value, onChange}] as const;
};

export default useControl;
