import React from 'react';

import {Pager, Pagination} from './pagination';


export const List = <T extends {id: any}>(
  {items, component: Component, pagination}: {
    items: T[],
    component: React.ComponentType<{item: T}>,
    pagination?: Pagination,
  }
) => {
  return (<>
    {items.map(item => <Component key={item.id} item={item} />)}
    {pagination && <Pager {...(pagination as Pagination)} />}
  </>);
};
