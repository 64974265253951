import React from 'react';
import {Helmet} from 'react-helmet';
import {HashRouter as Router, Link} from 'react-router-dom';
import {Translation} from 'react-i18next';
import HandleErrors from './HandleErrors';

import {Component as Opencast} from './opencast';
import {Authenticated} from './user';
import Login from './Login';

import sitemap, {Navigation, Routes} from './sitemap';
import {SearchBar} from './search';
import LanguageSwitcher from './LanguageSwitcher';

import styles from './App.module.css';

const App: React.FC = () =>
  <Router>
    <HandleErrors>
      <Translation>{t => {
        return <>
          <Helmet>
            <title>{
              t('VITAL—Video Portal of the Medical University of Graz')
            }</title>
          </Helmet>
          <Opencast>
            <Authenticated>
              <div className={styles.app}>
                <header className={styles.header}>
                  <h1 className={styles.logo}>
                    <Link to={sitemap.home.routeTo({})} className={styles.logoLink}>
                      <picture>
                        <source
                          srcSet="logos/small.svg"
                          media={`(max-width: ${styles["width-threshold"]})`}
                        />
                        <img
                          src={t('logos/en.svg')}
                          alt={t('Medical University of Graz')}
                        />
                      </picture>
                    </Link>
                  </h1>
                  <SearchBar />
                  <Login />
                  <LanguageSwitcher />
                </header>
                <nav className={styles.navigation}>
                  <div className={styles.links}>
                    <Navigation />
                  </div>
                </nav>
                <main className={styles.content}>
                  <Routes />
                </main>
              </div>
            </Authenticated>
          </Opencast>
        </>;
      }}</Translation>
    </HandleErrors>
  </Router>;

export default App;
