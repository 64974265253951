import React from 'react';

import useControl from './useControl';

import Select, {createFilter} from 'react-select';

import {Trans, useTranslation} from 'react-i18next';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faFastBackward,
  faStepBackward,
  faStepForward,
  faFastForward,
  faRedo,
} from '@fortawesome/free-solid-svg-icons';

import styles from './Pager.module.css';
import buttonReset from './button-reset.module.css';

export const usePagination = (initialLimit: number = 10) => {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(initialLimit);
  return {limit, setLimit, page, setPage};
};

export type Pagination = {
  total: number,
} & ReturnType<typeof usePagination>;

const ogSelectFilter = createFilter(null);
const selectFilter: typeof ogSelectFilter = ({label, data}, query) =>
  ogSelectFilter({label, value: '', data}, query);

export const Pager: React.FC<Pagination> =
  ({total, limit, setLimit, page: currentPage, setPage}) => {
    const {t} = useTranslation();
    const pages = Math.ceil(total / limit);
    const selectOptions = Array(pages);
    for (let page = 0; page < pages; ++page) {
      selectOptions[page] = {
        label: t('Page {{page}}', {page: page + 1}),
        value: page,
      };
    }
    const [newLimit, setNewLimit, controlNewLimit] = useControl(limit);
    const totalDigits = Math.ceil(Math.log10(total));
    return <div className={styles.controls}>
      {limit < total
        ? <><div className={styles.step}>
          <button
            className={buttonReset.reset}
            onClick={() => setPage(0)}
            disabled={currentPage === 0}
          ><FontAwesomeIcon icon={faFastBackward} /></button>
          <button
            className={buttonReset.reset}
            onClick={() => setPage(page => Math.max(0, page - 1))}
            disabled={currentPage === 0}
          ><FontAwesomeIcon icon={faStepBackward} /></button>
        </div>
        <div>
          <Select
            styles={{
              singleValue: ({maxWidth, position, transform, ...rest}) => rest,
              menu: styles => ({
                ...styles,
                whiteSpace: 'nowrap',
                width: 'auto',
                left: 0,
              }),
              menuList: styles => ({
                ...styles,
                overflowY: 'scroll',
              }),
            }}
            menuPlacement="auto"
            menuShouldScrollIntoView={true}
            value={selectOptions[currentPage]}
            onChange={({value}) => setPage(value)}
            filterOption={selectFilter}
            options={selectOptions}
            classNamePrefix="pager"
          />
        </div>
        <div className={styles.step}>
          <button
            className={buttonReset.reset}
            onClick={() => setPage(page => Math.min(pages - 1, page + 1))}
            disabled={currentPage === pages - 1}
          ><FontAwesomeIcon icon={faStepForward} /></button>
          <button
            className={buttonReset.reset}
            onClick={() => setPage(pages - 1)}
            disabled={currentPage === pages - 1}
          ><FontAwesomeIcon icon={faFastForward} /></button>
        </div></>
        : <div className={styles.placeholder} />}
      <form
        className={styles.limit}
        onSubmit={event => {
          event.preventDefault();
          const parsedLimit = Number(newLimit);
          if (isNaN(parsedLimit)) {
            setNewLimit(limit);
          } else {
            setLimit(parsedLimit);
          }
        }}
      >
        <Trans>Items per page</Trans>:
        <input
          {...controlNewLimit}
          minLength={1}
          maxLength={totalDigits}
          size={totalDigits}
        />
        <button className={buttonReset.reset}>
          <FontAwesomeIcon icon={faRedo} />
        </button>
      </form>
    </div>
  };
