import React, {ErrorInfo} from 'react';
import {RouteComponentProps, withRouter, Link} from 'react-router-dom';
import styles from './App.module.css';
import sitemap from './sitemap';

interface ErrorState {
  readonly error?: any;
  readonly secondOne?: boolean;
}

const HandleErrors = withRouter(
  class extends React.Component<RouteComponentProps, ErrorState> {
    readonly state: ErrorState = {};

    componentWillMount() {
      window.addEventListener('error', this.setErrorState, true);
      window.addEventListener('unhandledrejection', this.setErrorState);
      this.props.history.listen(() => this.setState(
        {error: undefined, secondOne: undefined}
      ));
    }

    componentWillUnmount() {
      window.removeEventListener('error', this.setErrorState, true);
      window.removeEventListener('unhandledrejection', this.setErrorState);
    }

    setErrorState = (error: any) => {
      // Error events are caused by global unhandled script errors
      // and when the browser can't load certain resources.
      // (Think `img`, etc.)
      // The two cases can be distinguished by the type of the event.
      // Script errors explicitly lead to an `ErrorEvent`.
      if (error instanceof ErrorEvent) {
        console.error(error);
        if (this.state.error != null) {
          this.setState({error, secondOne: true });
        } else {
          this.setState({error});
        }
      }
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
      this.setErrorState(error);
    }

    render() {
      if (this.state.error == null) {
        return this.props.children;
      }

      return this.state.secondOne
        ? <BackupErrorPage />
        : <ErrorPage error={this.state.error} />;
    }
  }
);

export default HandleErrors;

// The standard error page. It looks roughly like the normal page layout to
// avoid users panicking. We already use minimal code to avoid further errors.
// In case a second error occurs, `BackupErrorPage` is used.
const ErrorPage = (error?: any) => (
  <div className={styles.app}>
    <header className={styles.header}>
      <h1 className={styles.logo}>
        <Link to={sitemap.home.routeTo({})} className={styles.logoLink}>
          <img src='logos/en.svg' alt='logo' />
        </Link>
      </h1>
    </header>
    <nav className={styles.navigation}>
      <div className={styles.links}></div>
    </nav>
    <main className={styles.content}>
      <h2>Error :-(</h2>
      <p>
        A fatal error has occured which cannot be handled by this application.
        Please contact your system administrator.
        {' '}<Link to={sitemap.home.routeTo({})}>Back to home page</Link>.
      </p>
      <p>
        Es ist ein schwerer Fehler aufgetreten, der nicht von dieser Applikation
        behandelt werden kann. Bitte wenden Sie sich an Ihren Systemadministrator.
        {' '}<Link to={sitemap.home.routeTo({})}>Zurück zur Startseite</Link>.
      </p>
    </main>
  </div>
);

// Super plain and simple error page. This should (very strong "should"!) not
// cause any additional errors.
const BackupErrorPage = () => (
  <>
    <h1>Error :-(</h1>
    <p>
      A fatal error occured. While displaying the error message for that error,
      another error occured. That's not good! Please contact your system administrator.
    </p>
  </>
);
